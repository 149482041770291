<template>
  <div>
    <basic-container>
      <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @selection-change="selectionChange"
                 @size-change="sizeChange"
                 @current-change="currentChange"
      >
        <template slot-scope="scope" slot="menu">
            <el-button  size="text" @click.native="handleUpdate(scope.row,scope.index)">{{$t('Edit')}}</el-button>
            <el-button  size="text"  @click.native="handleDel(scope.row)">{{$t('Delete')}}</el-button>
        </template>
      </avue-crud>
<!--      编辑弹窗-->
      <edit @add="add" :selectItem="selectItem" v-if="showEdit"  :modelShow="showEdit" @closeModel="closeModel"></edit>
    </basic-container>
  </div>
</template>
<script>
import edit from './components/edit'
export default {
  components: {
    edit
  },
  data() {
    return {
      nameOrCode: "",
      processName: "",
      modelType: "",
      departmentName: "",
      tableLoading:false,
      showEdit:false,
      page: {
        pageSize: 10,
        pageNo:1,
        pagerCount: 5,
        total: 1,
        pageCurrent: 1,
        nameOrCode:'',
        dataType:'',
        categoryId:'machineType'

      },
      disabled: true,
      tableData: [],
      tableOption: {
        delBtn: false,
        editBtn:false,
        menuWidth: 400,
        selection: false,
        tip: false,
        columnBtn: false,
        addBtn:false,
        refreshBtn:false,
        column: [
          // {
          //   label: "设备机型",
          //   prop: "name",
          //   filter:true,
          //   dicData:[],
          //   filterMethod:function(value, row, column) {
          //     return row.name === value;
          //   }
          // },
          {
            label: "名称",
            prop: "name",
            filter:true,
            dicData:[],
            filterMethod:function(value, row, column) {
              return row.name === value;
            }
          },{
            label: "编码",
            prop: "code",
            filter:true,
            dicData:[],
            filterMethod:function(value, row, column) {
              return row.code === value;
            }
          }, {
            label: "工序",
            prop: "processName"
          },{
            label: "设备类别",
            prop: "typeName"
          }, {
            label: "功率(kw/h)",
            prop: "power"
          },
          {
            label: "创建人",
            prop: "createUserName",
            // hide: true,
          },
          {
            label: "创建时间",
            prop: "createTime",
            // hide: true,
          }
          ]
      },
      selectItem:{}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 删除
    handleDel(row) {
      this.$confirm("确定要删除吗", "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.request.delete(this.proApis.MACHINEMODEDELETE + '?id=' + row.id).then(()=>{
          this.getList()
          this.$message.success('删除成功')
        })
      })
    },
    add(val){
      console.log(val)
      let params = {
        ...val.form,
        iotColumnsList:val.tableData
      }
      console.log(params,'params')
      this.request.post(this.proApis.ADDIOTCOLUMNMACHINEMODEL,params).then(()=>{
        this.getList()
        this.$message.success('编辑成功')
        this.showEdit = false
      })
    },
    // 编辑
    handleUpdate(row, index ) {
      console.log(index,row)
      this.showEdit = true
      this.selectItem = row
    },
    selectionChange(list) {
      console.log(list);
      list != "" ? this.disabled = false : this.disabled = true;
    },
    async getList() {
      const  res = await this.request.post(this.proApis.MACHINEMODELSELECTPAGE,this.page)
      this.page.total = res.data.data.total
      this.tableData = res.data.data.items
      res.data.data.items.forEach(item=>{
        this.tableOption.column[0].dicData.push({
          label:item.name,
          value:item.name
        })
        this.tableOption.column[1].dicData.push({
          label:item.code,
          value:item.code
        })
      })
      console.log(res.data.data,'res')
    },
    sizeChange(val){
      this.page.pageNo = 1
      this.page.pageSize = val
      this.getList()
    },
    currentChange(val){
      this.page.pageNo = val
      this.getList()
    },
    closeModel(){
      this.showEdit = false
    }
  }
}
</script>
<style scoped>
.el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  padding-left: 10px;
}
</style>